'use client'
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { BookingOptions, bookingQueryKeys, getBookingOptions } from '../services';

type UseGetBookingOptionsParams = UseQueryOptions<BookingOptions, unknown, BookingOptions, string[]> & {
    booking: Parameters<typeof getBookingOptions>[0];
};
export const useGetBookingOptions = ({ booking, ...options }: Omit<UseGetBookingOptionsParams, 'queryKey' | 'queryFn'>) =>
    useQuery({
        queryKey: bookingQueryKeys.options(booking.reservationReference || ''),
        queryFn: () => getBookingOptions(booking),
        ...options,
    });
