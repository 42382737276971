'use client';

import { useContext } from 'react';
import { SiteProviderContext } from '@/components/Providers/contexts/SiteProviderContext';

export const useSite = () => {
    const site = useContext(SiteProviderContext)?.site;
    if (!site) {
        throw new Error('useSite must be used within a SiteProviderContext.');
    }

    return site;
};
