'use client'
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { FleetType, fleetTypeQueryKeys, getFleetTypesWithPreloadedImages } from '../services';

type UseGetFleetTypesOptions = UseQueryOptions<FleetType[], Error, FleetType[], string[]>;

export const useGetFleetTypes = (options?: Omit<UseGetFleetTypesOptions, 'queryKey' | 'queryFn'>) =>
    useQuery({
        queryKey: fleetTypeQueryKeys.list(),
        queryFn: getFleetTypesWithPreloadedImages,
        ...options,
    });

