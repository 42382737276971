'use client'
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { Site, branchQueryKeys, getSites } from '../services';

type UseGetBranchesOptions = UseQueryOptions<Site[], Error, Site[], string[]>;

export const useGetBranches = (options?: Omit<UseGetBranchesOptions, 'queryKey' | 'queryFn'>) =>
    useQuery({
        queryKey: branchQueryKeys.list(),
        queryFn: getSites,
        ...options,
    });
