'use client';

import { useLocale } from '@jucy-ui/components';
import { useSite } from '@/hooks/useSite';

export const useGetSitePrefix = () => {
    const currentSite = useSite();
    const locale = useLocale();
    return `/${currentSite.urlSegment}/${locale.split('_')[0]}`;
};
