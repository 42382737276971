import { Deal } from '@jucy/rentals-api-client';
import { AgentApi } from '@jucy/rentals-api-client/rentals-api-v3/apis/AgentApi';
import { CatalogApi } from '@jucy/rentals-api-client/rentals-api-v3/apis/CatalogApi';
import { CountryApi } from '@jucy/rentals-api-client/rentals-api-v3/apis/CountryApi';
import { FleetTypesApi } from '@jucy/rentals-api-client/rentals-api-v3/apis/FleetTypesApi';
import { HirePeriodsApi } from '@jucy/rentals-api-client/rentals-api-v3/apis/HirePeriodsApi';
import { type ConvertQuoteRequest, QuotesApi } from '@jucy/rentals-api-client/rentals-api-v3/apis/QuotesApi';
import { ReservationsApi } from '@jucy/rentals-api-client/rentals-api-v3/apis/ReservationsApi';
import { SitesApi } from '@jucy/rentals-api-client/rentals-api-v3/apis/SitesApi';
import { TripApi } from '@jucy/rentals-api-client/rentals-api-v3/apis/TripApi';
import { BookingFromJSON } from '@jucy/rentals-api-client/rentals-api-v3/models';
import * as runtime from '@jucy/rentals-api-client/rentals-api-v3/runtime';
import { DealsApi } from '@jucy/rentals-api-client/rentals-api/apis/DealsApi';
import type { Configuration as V2Configuration } from '@jucy/rentals-api-client/rentals-api/runtime';
import { formatISO9075 } from 'date-fns';
import { loadImage, setTime } from '../lib';
import { mapFleetTypes, mapSearchValuesToRequest, mapSitesToRegion, mapUpdateBookingValuesToRequest } from './mappers';
import {
    AvailabilitySearchValues,
    type CatalogItem,
    type CreateBookingRequestValues,
    EmailQuoteRequestValues,
    type GetBookingRequest,
    HirePeriod,
    JucyCountryCode,
    type ListDealsRequest,
    OperatorDetails,
    PaymentConfigFromJSON,
    type PaymentTokenRequest,
    type PaymentTokenResponse,
    TripInfoRequestValues,
    UpdateBookingValues,
} from './models';
import { rentalsApiConfig } from './rentalsApiConfig';

export * from './models';

export const getSites = async () => new SitesApi(rentalsApiConfig()).listSites('all');

export const getRegions = async () => {
    const sites = await getSites();
    return mapSitesToRegion({ sites: sites });
};

export const getFleetTypes = async () => {
    const fleetTypes = await new FleetTypesApi(rentalsApiConfig()).getFleetTypes();
    return mapFleetTypes(fleetTypes);
};

export const getHirePeriods = async (): Promise<HirePeriod[]> => {
    const hirePeriods = await new HirePeriodsApi(rentalsApiConfig()).listHirePeriods();
    return hirePeriods;
};

export const getProductCatalog = async (): Promise<CatalogItem[]> => {
    const productCatalog = await new CatalogApi(rentalsApiConfig()).getRentalCatalog();
    return productCatalog.products;
};

export const getProductCatalogItem = async (fleetCategoryCode: string): Promise<CatalogItem | undefined> => {
    const productCatalog = await new CatalogApi(rentalsApiConfig()).getRentalCatalog(fleetCategoryCode);
    return productCatalog.products.find((item) => item.code === fleetCategoryCode) || undefined;
};

export const getBookingEntities = async (
    booking: Partial<{
        reservationReference: string;
        fleetCategory: { code: string; type: string };
        pickUpLocation: string;
        dropOffLocation: string;
    }>
) => {
    const [sites, fleetTypes, catalogItem] = await Promise.all([
        getSites(),
        getFleetTypes(),
        getProductCatalogItem(booking?.fleetCategory?.code || ''),
    ]);
    return {
        catalogItem,
        pickUpBranch: sites.find((s) => s.siteCode === booking?.pickUpLocation),
        dropOffBranch: sites.find((s) => s.siteCode === booking?.dropOffLocation),
        fleetType: fleetTypes.find((ft) => ft.slug === booking?.fleetCategory?.type),
    };
};
export const getBooking = async (request: GetBookingRequest) => {
    const response = await new ReservationsApi(rentalsApiConfig()).request({
        path: `/api/v3/reservations/${request.reservationReference}`,
        method: 'GET',
        headers: {},
        query: {
            lastName: request.lastName || '',
            pickUpBranchCode: request.pickUpBranchCode || '',
        },
    });
    return await new runtime.JSONApiResponse(response, (jsonValue) => BookingFromJSON(jsonValue)).value();
};

export const getBookingOptions = async (booking: Partial<{ reservationReference: string }>) =>
    new ReservationsApi(rentalsApiConfig()).getOptions(booking.reservationReference || '');

export const getCountries = async () => new CountryApi(rentalsApiConfig()).getCountries();

export const getAvailability = async (searchValues: AvailabilitySearchValues) => {
    const request = mapSearchValuesToRequest(searchValues);
    return new TripApi(rentalsApiConfig()).availabilitySearch(
        request.pickUpLocation,
        request.dropOffLocation,
        request.pickUpDate,
        request.dropOffDate,
        request.fleetTypeCode,
        request.driverAge,
        request.campaignCode,
        request.mergeSurchargesFees,
        request.reservationReference
    );
};

export const validateUpdateReservation = async (update: UpdateBookingValues) => {
    const { reservationRef, updateReservationRequest } = mapUpdateBookingValuesToRequest(update);
    return new ReservationsApi(rentalsApiConfig()).validateUpdateReservation(reservationRef, updateReservationRequest);
};
export const updateReservation = async (update: UpdateBookingValues) => {
    const { reservationRef, updateReservationRequest } = mapUpdateBookingValuesToRequest(update);
    return new ReservationsApi(rentalsApiConfig()).updateReservation(reservationRef, updateReservationRequest);
};
export const getAgentDetails = async (key: string) => new AgentApi(rentalsApiConfig()).getAgentDetails(key);
export const getPaymentConfig = async ({ countryCode }: { countryCode: JucyCountryCode }) => {
    const data = await new ReservationsApi(rentalsApiConfig()).request({
        path: '/api/v3/payment/config',
        method: 'GET',
        headers: {},
        query: { countryCode },
    });
    return PaymentConfigFromJSON(await data.json());
};

export const getTripInfo = async (searchValues: TripInfoRequestValues) => {
    const request = mapSearchValuesToRequest(searchValues as AvailabilitySearchValues);
    return new TripApi(rentalsApiConfig()).tripInfo(
        request.pickUpLocation,
        request.dropOffLocation,
        request.pickUpDate,
        request.dropOffDate,
        searchValues.fleetCategory,
        request.driverAge,
        1,
        request.campaignCode
    );
};

export const getOperatorInfo = async (token: string) => {
    const data = await new ReservationsApi(rentalsApiConfig()).request({
        path: '/api/v3/operator/current',
        method: 'GET',
        headers: {
            ['x-operator-access-token']: token,
        },
    });
    return (await data.json()) as OperatorDetails;
};

export const emailQuote = async (request: EmailQuoteRequestValues) =>
    new QuotesApi(rentalsApiConfig()).emailQuote({
        pickUpLocation: request.pickUpBranch,
        dropOffLocation: request.dropOffBranch,
        pickUpDate: formatISO9075(setTime(request.pickUpDate, request.pickUpTime)),
        dropOffDate: formatISO9075(setTime(request.dropOffDate, request.dropOffTime)),
        driverAge: request.driverAge ? Number(request.driverAge) : undefined,
        campaignCode: request.promoCode,
        fleetCategory: request.fleetCategory,
        hirerDetails: {
            firstName: request.hirerDetails.firstName,
            lastName: request.hirerDetails.lastName,
            mobileNumber: request.hirerDetails.mobileNumber,
            email: request.hirerDetails.email,
            driversLicenceCountry: request.hirerDetails.driversLicenceCountry,
            acceptedTerms: request.hirerDetails.acceptedTerms,
            mailingList: Boolean(request.hirerDetails.mailingList),
        },
        insurance: request.excessReduction,
        reservationReference: request.reservationReference,
        secondaryProducts: request.secondaryProducts || {},
    });

export const createBooking = async (request: CreateBookingRequestValues) => {
    const mappedRequest = {
        ...request,
        pickUpLocation: request.pickUpBranch,
        dropOffLocation: request.dropOffBranch,
        pickUpDate: formatISO9075(setTime(request.pickUpDate, request.pickUpTime)),
        dropOffDate: formatISO9075(setTime(request.dropOffDate, request.dropOffTime)),
        driverAge: request.driverAge ? Number(request.driverAge) : undefined,
        campaignCode: request.promoCode,
        fleetCategory: request.fleetCategory,
        hirerDetails: {
            firstName: request.hirerDetails.firstName,
            lastName: request.hirerDetails.lastName,
            mobileNumber: request.hirerDetails.mobileNumber,
            email: request.hirerDetails.email,
            driversLicenceCountry: request.hirerDetails.driversLicenceCountry,
            acceptedTerms: request.hirerDetails.acceptedTerms,
            mailingList: Boolean(request.hirerDetails.mailingList),
        },
        insurance: request.excessReduction,
        secondaryProducts: request.secondaryProducts || {},
    };
    const apiClient = new ReservationsApi(rentalsApiConfig());
    return request.reservationReference
        ? apiClient.updateReservation(request.reservationReference, mappedRequest)
        : apiClient.createBooking({
              ...mappedRequest,
              type: 'booking',
          });
};

export const getPaymentToken = async (request: PaymentTokenRequest) => {
    const response = await new ReservationsApi(rentalsApiConfig()).request({
        path: `/api/self-service/reservation/ref/${request.reservationReference}/payment-token`,
        method: 'GET',
        headers: {},
        query: {
            gateway: request.gateway,
            storeCard: request.storeCard ? 'true' : 'false',
            ...(request.returnUrl ? { returnUrl: request.returnUrl } : {}),
            ...(request.amount ? { amount: request.amount } : {}),
        },
    });
    return (await response.json()) as PaymentTokenResponse;
};

export const confirmPaymentIntent = async ({
    url: passedUrl,
    paymentIntent,
}: {
    url: string;
    paymentIntent: { id: string; client_secret: string | null };
}) => {
    const url = new URL(passedUrl);
    url.searchParams.set('redirect', 'false');
    url.searchParams.set('callbackResult', 'success');
    url.searchParams.set('payment_intent', paymentIntent.id);
    url.searchParams.set('payment_intent_client_secret', paymentIntent.client_secret || '');
    const response = await new ReservationsApi(rentalsApiConfig()).request({
        path: url.pathname,
        method: 'GET',
        headers: {},
        query: Object.fromEntries(url.searchParams),
    });
    return (await response.json()) as { result: boolean };
};

export const confirmSetupIntent = async ({
    url: passedUrl,
    setupIntent,
}: {
    url: string;
    setupIntent: { id: string; client_secret: string | null };
}) => {
    const url = new URL(passedUrl);
    url.searchParams.set('redirect', 'false');
    url.searchParams.set('callbackResult', 'success');
    url.searchParams.set('storeCard', 'true');
    url.searchParams.set('setup_intent', setupIntent.id);
    url.searchParams.set('setup_intent_client_secret', setupIntent.client_secret || '');
    const response = await new ReservationsApi(rentalsApiConfig()).request({
        path: url.pathname,
        method: 'GET',
        headers: {},
        query: Object.fromEntries(url.searchParams),
    });
    return (await response.json()) as { result: boolean };
};

export const convertQuote = async (req: ConvertQuoteRequest) => {
    const response = await new QuotesApi(rentalsApiConfig()).convertQuote(
        req.gateway || 'stripe',
        req.returnUrl,
        req.reservationRef,
        req.failedUrl,
        req.type
    );
    return response;
};

export const getDeals = async (data: ListDealsRequest): Promise<Deal[]> => {
    const deals = await new DealsApi(rentalsApiConfig() as unknown as V2Configuration).listDeals(
        data.accountId,
        data.tags,
        data.region,
        data.code,
        data.type
    );
    return deals.items || [];
};

export const getFleetTypesWithPreloadedImages = async () => {
    const fleetCategories = await getFleetTypes();
    await Promise.all(fleetCategories.map((fleetCategory) => fleetCategory.thumbnail && loadImage(fleetCategory.thumbnail))).catch((e) => {
        console.error('Failed to load fleet category images', e);
    });
    return fleetCategories;
};
