'use client'
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { Booking, bookingQueryKeys, getBooking } from '../services';

type UseGetBookingOptions = UseQueryOptions<Booking, Error, Booking, string[]> & {
    request: Parameters<typeof getBooking>[0];
};
export const useGetBooking = ({ request, ...options }: Omit<UseGetBookingOptions, 'queryKey' | 'queryFn'>) =>
    useQuery({
        queryKey: bookingQueryKeys.get(request),
        queryFn: () => getBooking(request),
        ...options,
    });
