'use client'
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { Deal, ListDealsRequest, dealsQueryKeys, getDeals } from '../services';

type UseGetDealsOptions = UseQueryOptions<Deal[], Error, Deal[], string[]> & ListDealsRequest;

export const useGetDeals = ({ accountId, tags, region, code, type, ...options }: Omit<UseGetDealsOptions, 'queryKey' | 'queryFn'>) => {
    const dealRequest = {
        accountId,
        tags,
        region,
        code,
        type,
    };
    return useQuery({
        queryKey: dealsQueryKeys.list(dealRequest),
        queryFn: () => getDeals(dealRequest),
        ...options,
    });
};
