'use client';

import { useCallback } from 'react';
import { useGetSitePrefix } from '@/hooks/useGetSitePrefix';

export const useSiteLinkPrefix = () => {
    const prefix = useGetSitePrefix();
    return useCallback(
        (link: string) => {
            if (!link) {
                return prefix;
            }
            if (!link.startsWith('/')) {
                return link;
            }
            if (link.startsWith(prefix)) {
                return link;
            }
            const parts = [prefix, ...link.split('/').filter(Boolean)];
            return `${parts.join('/')}`;
        },
        [prefix]
    );
};
