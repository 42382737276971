'use client'
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AgentInfo, agentQueryKeys, getAgentDetails } from '../services';

type UseAgentDetailOptions = UseQueryOptions<AgentInfo | undefined, Error, AgentInfo, string[]>;

export const useGetAgentDetails = (accountId?: string, options?: Omit<UseAgentDetailOptions, 'queryKey' | 'queryFn'>) => {
    const enabled = Boolean(accountId);
    return useQuery({
        queryKey: agentQueryKeys.details(accountId || ''),
        queryFn: () => (accountId ? getAgentDetails(accountId) : undefined),
        ...options,
        enabled: options?.enabled !== undefined ? options.enabled && enabled : enabled,
    });
};
