import { QueriesOptions, useQueries } from '@tanstack/react-query';
import type { GenericUseQueryOptions } from '@jucy-ui/common/lib/ReactQueryUtils';
import {
    FleetType,
    Site,
    branchQueryKeys,
    fleetTypeQueryKeys,
    getFleetTypesWithPreloadedImages,
    getProductCatalog,
    getSites,
    productCatalogQueryKeys,
} from '@jucy-ui/common/services';
import type { CatalogItem } from '@jucy-ui/common/services/models';

export type UseGetTripEntitiesOptions = {
    trip?: {
        pickUpLocation?: string;
        pickUpBranch?: string;
        dropOffLocation?: string;
        dropOffBranch?: string;
        fleetCategory?: string;
        fleetType?: string;
    } | null;
} & Omit<GenericUseQueryOptions, 'queryKey' | 'queryFn'>;
export const useGetTripEntities = ({ trip, enabled, ...queryOptions }: UseGetTripEntitiesOptions) => {
    const pickUpLocationCode = trip?.pickUpLocation || trip?.pickUpBranch;
    const dropOffLocationLocationCode = trip?.dropOffLocation || trip?.dropOffBranch;
    const fleetCategoryCode = trip?.fleetCategory;
    const fleetTypeSlug = trip?.fleetType;
    return useQueries({
        queries: [
            {
                queryKey: branchQueryKeys.list(),
                queryFn: getSites,
                enabled: Boolean(enabled && (pickUpLocationCode || dropOffLocationLocationCode)),
                ...(queryOptions as QueriesOptions<Site[]>),
            },
            {
                queryKey: productCatalogQueryKeys.list(),
                queryFn: getProductCatalog,
                enabled: Boolean(enabled && fleetCategoryCode),
                ...(queryOptions as QueriesOptions<CatalogItem[]>),
            },
            {
                queryKey: fleetTypeQueryKeys.list(),
                queryFn: getFleetTypesWithPreloadedImages,
                enabled: Boolean(enabled && fleetTypeSlug),
                ...(queryOptions as QueriesOptions<FleetType[]>),
            },
        ],
        combine: ([sitesQuery, productCatalogQuery, fleetTypesQuery]) => ({
            data: {
                pickUpBranch: sitesQuery.data?.find((s) => s.siteCode === pickUpLocationCode),
                dropOffBranch: sitesQuery.data?.find((s) => s.siteCode === dropOffLocationLocationCode),
                catalogItem: productCatalogQuery.data?.find((catalogItem) => catalogItem.code === fleetCategoryCode),
                fleetType: fleetTypesQuery.data?.find((ft) => ft.slug === fleetTypeSlug),
            },
            isLoading: sitesQuery.isLoading || fleetTypesQuery.isLoading || productCatalogQuery.isLoading,
            error: sitesQuery.error || fleetTypesQuery.error || productCatalogQuery.error,
        }),
    });
};
