'use client'
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CatalogItem, getProductCatalog, productCatalogQueryKeys } from '../services';

type UseGetFleetTypesOptions = UseQueryOptions<CatalogItem[], Error, CatalogItem[], string[]>;

export const useGetProductCatalog = (options?: Omit<UseGetFleetTypesOptions, 'queryKey' | 'queryFn'>) =>
    useQuery({
        queryKey: productCatalogQueryKeys.list(),
        queryFn: getProductCatalog,
        ...options,
    });
