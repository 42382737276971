'use client';

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { bookingQueryKeys, getBookingEntities } from '../services';
import { TripDetails } from '../services/models/TripDetails';
import { BookingEntities } from './useGetBookingEntities';

export type UseGetTripEntitiesOptions = Omit<UseQueryOptions<BookingEntities, Error, BookingEntities, string[]>, 'queryKey' | 'queryFn'> & {
    trip?: TripDetails | null;
};
export const useGetTripEntities = ({ trip, ...options }: Omit<UseGetTripEntitiesOptions, 'queryKey' | 'queryFn' | 'initialData'>) => {
    const enabled = Boolean(trip?.pickUpLocation && trip?.dropOffLocation && trip?.fleetCategory?.type && trip?.fleetCategory?.code);
    const key = [trip?.pickUpLocation, trip?.dropOffLocation, trip?.fleetCategory?.type, trip?.fleetCategory?.code].filter(Boolean).join('-');

    return useQuery({
        ...options,
        queryKey: bookingQueryKeys.entities(key || ''),
        queryFn: async () => {
            const data = trip ? await getBookingEntities(trip) : null;
            return (data || {}) as BookingEntities;
        },
        enabled: options.enabled !== undefined ? options.enabled : enabled,
    });
};
